.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.address-input {
  width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 0.5px solid lightgrey;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  margin-bottom: 10px;
  margin-left: 0;
}

.check-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.check-button:hover {
  background-color: #45a049;
}

.disclaimer {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 20px;
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
}

.disclaimer p {
  margin: 0;
}

.status-text {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.popup {
  padding: 10px;
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 500px;
  margin: 0 auto;
  position: relative;
  top: -4px;
  font-weight: 500;
}

@media (max-width: 500px) {
  .address-input {
    width: 200px;
    font-size: 14px;
  }

  .check-button {
    font-size: 14px;
    margin-left: 10px;
  }
  .disclaimer-note {
    font-size: 12px;
  }
  .popup {
    width: 300px;
  }

  .legend h3 {
    font-size: 14px;
  }
  .legend p {
    font-size: 14px;
  }
}

.disclaimer-note {
  color: #ff0000;
  font-weight: bold;
  margin-top: 10px;
}

.popup.fluoride {
  background-color: #1da1f4;
}

.popup.no-fluoride {
  background-color: #1da1f4;
}

.legend {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.legend h3 {
  margin: 5px;
}
.legend p {
  margin: 5px;
}
.color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.blue {
  background: blue;
}
.green {
  background: green;
}
.orange {
  background: rgba(255, 165, 0, 0.5);
}
.light-blue {
  background: #1da1f4;
}
